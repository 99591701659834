import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export enum USER_TYPES {
  HEALTH_PROFESSIONAL = 'h',
  PATIENT = 'p',
}
enum GENDERS {
  MALE = 'm',
  FEMALE = 'f',
  PREFER_NOT_TO_SAY = 'n',
}
enum EMPLOYMENT_STATUSES {
  UNEMPLOYED = 'u',
  SELF_EMPLOYED = 's',
  EMPLOYED = 'e',
}

interface User {
  id: number;
  is_authenticated: boolean;
  first_name: string;
  last_name: string;
  is_admin: boolean;
  profile: {
    type: USER_TYPES | undefined;
    gender: GENDERS | undefined;
    date_of_birth: string;
    id_number: string;
    id_type: string;
    residential_address: string;
    employment_status: EMPLOYMENT_STATUSES | undefined;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User = {
    id: 0,
    is_authenticated: true,
    first_name: '',
    last_name: '',
    is_admin: false,
    profile: {
      type: undefined,
      gender: undefined,
      date_of_birth: '',
      id_number: '',
      id_type: '',
      residential_address: '',
      employment_status: undefined,
    },
  };
  selectedUserProfile: 'p' | 'h' | undefined;
  constructor(private apiService: ApiService) {}

  init(body: FormData) {
    return this.apiService.init(body);
  }

  login(body: FormData) {
    return this.apiService.login(body);
  }

  getBaseUrl() {
    return this.apiService.getBaseUrl();
  }

  loginViaGoogle() {
    return this.apiService.loginViaGoogle();
  }

  async logout() {
    this.apiService.logout().subscribe(
      () => {
        window.location.reload();
      },
      (error) => {
        console.error('Error logging out:', error);
      }
    );
  }
}

import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular/standalone';

export enum LOCAL_STORAGE {
  ONBOARDING_BACKLOG = 'onboardingBacklog',
  ONBOARDING_DONE = 'onboardingDone',
}

@Injectable({
  providedIn: 'root',
})
export class CommonUtilsService {
  constructor(private toastController: ToastController) {}

  async presentToast(
    message: string,
    position: 'top' | 'middle' | 'bottom',
    duration?: number,
    onDidDismiss?: any
  ) {
    let parms: ToastOptions;
    if (duration === undefined) {
      parms = {
        // header: 'Error',
        message: message,
        position: position,
        buttons: [
          {
            text: 'Dismiss',
            role: 'cancel',
            handler: () => {},
          },
        ],
        layout: 'stacked',
      };
    } else {
      parms = {
        message: message,
        position: position,
        duration: duration,
      };
    }
    const toast = await this.toastController.create(parms);

    await toast.present();
    toast.onDidDismiss().then((data) => {
      console.log('Dismissed:', data);
      if (onDidDismiss != null) {
        onDidDismiss();
      }
    });
  }
}

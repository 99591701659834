import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { AuthService } from './shared/auth.service';

/* export const authGuard: CanActivateChildFn = (childRoute, state) => {
  return true;
}; */

export const authGuard = (): CanActivateChildFn => {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    if (authService.user.is_authenticated) {
      console.log('[Mi-Clinic] User is authenticated');
      return true;
    } else {
      console.log('[Mi-Clinic] User is not authenticated');
      return router.parseUrl('/login');
    }
  };
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  public getBaseUrl() {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      ':' +
      environment.hostPort
    );
  }

  public init(formData: FormData) {
    return this.httpClient
      .post(this.getBaseUrl() + '/clinic', formData, {
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }

  public login(formData: FormData) {
    return this.httpClient
      .post(this.getBaseUrl() + '/authentication/login', formData, {
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }

  loginViaGoogle() {
    Browser.addListener('browserFinished', () => {
      window.location.href = '/';
    });

    Browser.open({ url: this.getBaseUrl() + '/accounts/google/login' });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
      // return throwError(() => new Error(error.error));
    }
    // Return an observable with a user-facing error message.
    return throwError(() => error);
  }

  public logout() {
    return this.httpClient
      .get(this.getBaseUrl() + '/authentication/logout', {
        withCredentials: true,
      })
      .pipe(catchError(this.handleError));
  }
}

import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { AuthService } from 'src/app/services/shared/auth.service';
import {
  CommonUtilsService,
  LOCAL_STORAGE,
} from 'src/app/services/shared/common-utils.service';

@Component({
  selector: 'miclinic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  constructor(
    public authService: AuthService,
    private commonUtils: CommonUtilsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private storage: Storage
  ) {
    console.log('[Login constructor] User:', this.authService.user);
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  async login() {
    const formData = new FormData();
    formData.set('username', this.loginForm.get('email')?.value);
    formData.set('password', this.loginForm.get('password')?.value);
    console.log('Will login...');
    this.authService.login(formData).subscribe(
      (data: any) => {
        this.authService.user = data.user;
        console.log('Logged in:', this.authService.user);
        /* this.storage.set(
          LOCAL_STORAGE.ONBOARDING_BACKLOG,
          ONBOARDING_TODO_LIST.GO_TO_LANDING_PAGE
        ); */
        this.router.navigate(['/'], { replaceUrl: true });
        // location.reload();
      },
      (error) => {
        if (error.status == 403) {
          this.commonUtils.presentToast(
            'Invalid email or password',
            'middle',
            1000
          );
        }
      }
    );
    /* let res = await this.authService.login(formData);
    console.log('Nazo:', res); */
    /* res?.then((data) => {
    }); */
  }
}
